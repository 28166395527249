import paymentService from "api/payment/payment.service";
import { useClickOutside } from "hooks/useClickOutside";
import { useIsMobile } from "hooks/useIsMobile";
import { IconUser } from "icons";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { setShowLeaveFeedback, signOut } from "store/commonSlice";
import { AnalyticsEvent } from "utils";

const MenuItem = ({ children, onClick }: { children: React.ReactNode; onClick?: any }) => (
  <div className="flex cursor-pointer px-2.5 py-3 hover:bg-bg-2 rounded-2xl" onClick={onClick}>
    <h6 className="text-h6">{children}</h6>
  </div>
);

const AvatarMenu = ({ setIsOpen }: { setIsOpen: any }) => {
  const { user } = useAppSelector((state) => state.common);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  useClickOutside(containerRef, () => {
    setIsOpen(false);
  });

  async function handleManageSubscription() {
    AnalyticsEvent("manage_subscription", "", "click");

    try {
      const response = await paymentService.manageSubscription();
      window.location.href = response?.portal_url;
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div ref={containerRef} className="flex flex-col pb-2.5 gap-2.5 w-[340px] rounded-[26px] bg-bg border border-border">
      <div className="flex p-[15px] items-center text-white gap-2.5 bg-bg-2 rounded-t-[25px]">
        <IconUser className="w-5 h-5" />
        <h6 className="text-h6">{user?.email}</h6>
      </div>
      <div className="flex flex-col px-2.5 gap-2 text-white">
        {!isMobile && (
          <>
            <MenuItem
              onClick={() => {
                AnalyticsEvent("account_profile", "", "click");
                navigate("/account");
                setIsOpen(false);
              }}
            >
              Account
            </MenuItem>
            <MenuItem
              onClick={() => {
                AnalyticsEvent("history_opened", "", "click");
                navigate("/history");
                setIsOpen(false);
              }}
            >
              History
            </MenuItem>
            <MenuItem
              onClick={() => {
                AnalyticsEvent("favorites_opened", "", "click");
                navigate("/favorites");
                setIsOpen(false);
              }}
            >
              Favorites
            </MenuItem>
          </>
        )}
        <MenuItem
          onClick={() => {
            AnalyticsEvent("leave_feedback", "", "click");
            dispatch(setShowLeaveFeedback(true));
            setIsOpen(false);
          }}
        >
          Leave Feedback
        </MenuItem>
        <MenuItem onClick={handleManageSubscription}>Manage Subscription</MenuItem>
        <MenuItem
          onClick={() => {
            AnalyticsEvent("sign_out", "", "click");
            dispatch(signOut());
          }}
        >
          Sign Out
        </MenuItem>
      </div>
    </div>
  );
};

export default AvatarMenu;
