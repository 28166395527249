import React from "react";
import { IconClose, IconRightArrow2, LogoRefabric } from "icons";
import TryForFree from "components/TryForFree";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import { PATHS } from "router/config/paths";
import { AnalyticsEvent } from "utils";
import { APP_URL } from "utils/constants";

const body = document.querySelector("body");

const MobileHeaderMenu = ({ show, headerItems, onClose }: any) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (show && body) {
      body.style.overflow = "hidden";
    }

    return () => {
      if (body) body.style.overflow = "auto";
    };
  }, [show]);

  return (
    <div className="fixed h-screen w-screen bg-white z-10">
      <div className="flex flex-col gap-5 fixed top-0 left-0 w-full z-20 bg-white h-full">
        <div className="flex items-center justify-between p-5 border-b border-grey-light">
          <LogoRefabric
            className="cursor-pointer"
            onClick={() => {
              AnalyticsEvent("header_mobile_refabric_logo", "", "click");
              navigate(PATHS.HOME);
              onClose();
            }}
          />
          <IconClose className="cursor-pointer w-6 h-6 flex-shrink-0" onClick={onClose} />
        </div>
        <div className="flex flex-col">
          {headerItems.map((item: any, index: number) => (
            <div
              key={`MobileHeaderMenuItem_${index}`}
              className="flex items-center justify-between p-5"
              onClick={() => {
                item.onClick();
                onClose();
              }}
            >
              <a className="text-h7-satoshi !text-[20px] !leading-[100%]">{item.title}</a>
              <IconRightArrow2 />
            </div>
          ))}
        </div>
        <div className="flex flex-col p-5 gap-3">
          <a
            href={APP_URL + "/signup"}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              AnalyticsEvent("header_mobile_signin", "", "click");
            }}
          >
            <Button className="btn-secondary btn-sm whitespace-nowrap w-full">Sign In</Button>
          </a>
          <TryForFree
            btnClassName="btn-primary btn-sm w-full"
            containerClassName="w-full"
            callback={() => {
              AnalyticsEvent("header_mobile_tryforfree", "", "click");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileHeaderMenu;
