import { yupResolver } from "@hookform/resolvers/yup";
import userService from "api/user/user.service";
import Button from "components/Button/Button";
import GoogleButton from "components/GoogleButton";
import Input from "components/Input/Input";
import { IconEyeOff, IconEyeOn } from "icons";
import { ImageSuccessLogo } from "images";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PATHS } from "router/config/paths";
import { AnalyticsEvent } from "utils";
import * as yup from "yup";

const schema = yup
  .object({
    email: yup.string().required("Please enter a valid e-mail address!").email("Please enter a valid e-mail address!"),
    password: yup.string().required("Please enter a password!"),
    confirmPassword: yup
      .string()
      .required("Please enter a password!")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
    name: yup.string(),
    work: yup.string(),
  })
  .required();

const SignUp = ({ email, setCurrentStep, onClose }: any) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showCheckYourEmail, setShowCheckYourEmail] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: email || "",
      password: "",
      confirmPassword: "",
      name: "",
      work: "",
    },
  });

  const onValid = (data: any) => {
    userService
      .register({ name: data.name, password: data.password, email: data.email, work: data.work })
      .then(() => {
        AnalyticsEvent("sign_up", "", "click");
        setShowCheckYourEmail(true);
      })
      .catch((e) => console.log(e));
  };
  const onHandleSubmit = () => {
    handleSubmit(onValid, (e) => console.log(e))();
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onHandleSubmit();
    }
  };

  const CheckYourEmail = () => (
    <div className="flex flex-col items-center gap-10">
      <img src={ImageSuccessLogo} className="" />
      <div className="flex flex-col items-center gap-2.5">
        <h2 className="text-h2 text-center">Check Your Email</h2>
        <span className="text-bodyMd text-center">We’ve sent you an verification e-mail to activate your account. If you don’t see it, you may need to check your spam folder.</span>
      </div>
      <span className="text-bodySm">
        Didn’t receive email? <u className="cursor-pointer">Resend</u>
      </span>
    </div>
  );

  return (
    <div className="flex flex-col gap-10">
      {showCheckYourEmail ? (
        <CheckYourEmail />
      ) : (
        <>
          <div className="flex flex-col items-center gap-2.5">
            <h2 className="text-h2">Sign Up</h2>
            <span className="text-bodyMd">
              Already have an account?
              <u className="cursor-pointer" onClick={() => setCurrentStep(0)}>
                Login here
              </u>
            </span>
          </div>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2.5">
              {/* <div className="flex items-center gap-[5px] rounded-[5px] p-[5px]" style={{ background: "rgba(98, 102, 240, 0.1)" }}>
                <IconShared className="w-[14px] h-[14px] text-purple" />
                <span className="text-bodySm text-purple">
                  You’ve been invited by <b>tugberkdilbaz</b>
                </span>
              </div> */}
              <Input onKeyDown={handleKeyPress} dixsabled error={errors.email?.message} {...register("email")} placeholder="Enter your email" containerClassName="w-full"></Input>
              <Input
                onKeyDown={handleKeyPress}
                type={showPassword ? "text" : "password"}
                error={errors.password?.message}
                {...register("password")}
                placeholder="Password"
                containerClassName="w-full"
                icon={
                  !showPassword ? (
                    <IconEyeOn className="flex-shrink-0 text-grey w-5 h-5 cursor-pointer" onClick={() => setShowPassword(true)} />
                  ) : (
                    <IconEyeOff className="flex-shrink-0 text-grey w-5 h-5 cursor-pointer" onClick={() => setShowPassword(false)} />
                  )
                }
              ></Input>
              <Input
                onKeyDown={handleKeyPress}
                type={showPassword ? "text" : "password"}
                error={errors.confirmPassword?.message}
                {...register("confirmPassword")}
                placeholder="Confirm Password"
                containerClassName="w-full"
                icon={
                  !showPassword ? (
                    <IconEyeOn className="flex-shrink-0 text-grey w-5 h-5 cursor-pointer" onClick={() => setShowPassword(true)} />
                  ) : (
                    <IconEyeOff className="flex-shrink-0 text-grey w-5 h-5 cursor-pointer" onClick={() => setShowPassword(false)} />
                  )
                }
              ></Input>
              <Button className="btn-primary w-full" onClick={onHandleSubmit}>
                Sign Up
              </Button>
              {/* <Input onKeyDown={handleKeyPress} type="name" error={errors.name?.message} {...register("name")} placeholder="Full Name" containerClassName="w-full"></Input> */}
              {/* <Input onKeyDown={handleKeyPress} type="work" error={errors.work?.message} {...register("work")} placeholder="Company Name (Optional)" containerClassName="w-full"></Input> */}
            </div>
            <div className="flex items-center gap-2.5">
              <div className="h-[1px] bg-grey-light w-full" /> <span className="text-bodyMd">or</span>
              <div className="h-[1px] bg-grey-light w-full" />
            </div>
            <GoogleButton text="Sign Up with Google" afterComplete={() => onClose()} />
            <span className="text-bodySm text-center">
              You’re accepting{" "}
              <u
                className="cursor-pointer"
                onClick={() => {
                  navigate(PATHS.TERMS_OF_USE);
                  onClose();
                }}
              >
                Terms of Use
              </u>{" "}
              and{" "}
              <u
                className="cursor-pointer"
                onClick={() => {
                  navigate(PATHS.PRIVACY_POLICY);
                  onClose();
                }}
              >
                Privacy Policy
              </u>{" "}
              by signing up.
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default SignUp;
