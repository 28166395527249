import React from "react";
import { IconInstagram, IconLinkedin, RefabricLogoBig } from "icons";
import { PATHS } from "router/config/paths";
import { BLOG_URL, INSTAGRAM_URL, LINKEDIN_URL } from "utils/constants";
import ContactUsModal from "components/ContactUsModal";

const LandingFooter = () => {
  const [showContactUsModal, setShowContactUsModal] = React.useState(false);

  return (
    <footer className="flex flex-col pb-10 p-[30px] lg:p-10 bg-green">
      {showContactUsModal && <ContactUsModal show={showContactUsModal} onClose={() => setShowContactUsModal(false)} />}

      <div className="flex flex-col lg:flex-row gap-10 lg:gap-0 justify-between pb-10 lg:pb-[60px] border-b">
        <RefabricLogoBig className="cursor-pointer !w-full !h-[60px] lg:!w-auto lg:!h-auto" />
        <div className="flex flex-wrap gap-y-8 gap-x-[120px] lg:gap-[120px] pr-10">
          <div className="flex flex-col gap-5">
            <span className="text-headline-satoshi">menu</span>
            <div className="flex flex-col gap-2">
              <a className="text-bodyMd" href={PATHS.FEATURES}>
                Features
              </a>
              <a className="text-bodyMd " href={PATHS.SHOWCASE}>
                Showcase
              </a>
              <a className="text-bodyMd " href={PATHS.PRICING}>
                Pricing
              </a>
              <a className="text-bodyMd " href={PATHS.AMBASSADORS}>
                Ambassadors
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <span className="text-headline-satoshi">company</span>
            <div className="flex flex-col gap-2">
              <a className="text-bodyMd" href={PATHS.ABOUT}>
                About
              </a>
              <a className="cursor-pointer text-bodyMd" onClick={() => setShowContactUsModal(true)}>
                Contact Us
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <span className="text-headline-satoshi">Resources</span>
            <div className="flex flex-col gap-2">
              <a href={BLOG_URL} target="blank" rel="noopener noreferrer" className="text-bodyMd">
                Blog
              </a>
              <a className="text-bodyMd" href={`${PATHS.PRICING}`}>
                FAQ
              </a>
              {/* <a className="text-bodyMd ">Case Studies</a> */}
              {/* <a className="text-bodyMd ">Tutorials</a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-5 lg:flex-row lg:justify-between pt-5">
        <div className="flex items-center gap-8">
          <a className="text-bodyMd " href={PATHS.PRIVACY_POLICY}>
            Privacy Policy
          </a>
          <a className="text-bodyMd " href={PATHS.TERMS_OF_USE}>
            Terms of Use
          </a>
        </div>
        <div className="flex items-center gap-2.5">
          <a href={INSTAGRAM_URL} target="blank" rel="noopener noreferrer">
            <IconInstagram className="w-8 h-8" />
          </a>
          <a href={LINKEDIN_URL} target="blank" rel="noopener noreferrer">
            <IconLinkedin className="w-8 h-8" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default LandingFooter;
